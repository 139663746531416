import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ExtendMetadataDto } from '../interfaces/extend-metadata.dto';
import { Observable } from 'rxjs';
import { MetaData } from '../interfaces/metadata';
import { HttpClient } from '@angular/common/http';
import { GeneralMapAssistantAnswer } from '../interfaces/general-map-assistant.answer';
import { SerializedMap } from '../interfaces/graph-node.interface';

@Injectable({ providedIn: 'root' })
export class OpenaiResource {
  private readonly baseUrl = environment.apiUrl;

  constructor(private readonly http: HttpClient) {
  }

  extendMetadata(dto: ExtendMetadataDto, mapId: string): Observable<MetaData[]> {
    return this.http.post<MetaData[]>(this.baseUrl + '/ai/extend-metadata', { dto, extendMetaOnly: true, mapId });
  }

  generateAnswer(question: string, serializedMap: SerializedMap): Observable<GeneralMapAssistantAnswer> {
    return this.http.post<GeneralMapAssistantAnswer>(this.baseUrl + '/ai/map-assistant', { question, serializedMap });
  }
}
