import { Injectable } from '@angular/core';
import { OpenaiResource } from '../resource/openai.resource';
import { MetaData } from '../interfaces/metadata';
import { Observable } from 'rxjs';
import { ExtendMetadataDto } from '../interfaces/extend-metadata.dto';
import { GeneralMapAssistantAnswer } from '../interfaces/general-map-assistant.answer';
import { SerializedMap } from '../interfaces/graph-node.interface';

@Injectable({ providedIn: 'root' })
export class OpenaiService {
  constructor(private resource: OpenaiResource) {
  }

  extendMetadata(dto: ExtendMetadataDto, mapId: string): Observable<MetaData[]> {
    return this.resource.extendMetadata(dto, mapId);
  }

  generateAnswer(question: string, serializedMap: SerializedMap): Observable<GeneralMapAssistantAnswer> {
    return this.resource.generateAnswer(question, serializedMap);
  }
}
