export function mapAnchorsToXYArray(anchors: number[]) {
  let result = [];

  // Iterate over the array in steps of 2 (for x and y coordinates)
  for (let i = 0; i < anchors.length; i += 2) {
    result.push({
      x: anchors[i],
      y: anchors[i + 1]
    });
  }

  return result;
}
