
  <div class="dashboard">
    <div *ngIf="hasNoMap"
         class="no-map-container pt-3 d-flex justify-content-center align-items-center"
         [class.sidebar-open]="isSideBarOpen">
      <h4 class="text-center d-flex justify-content-center align-items-center">
        Please use
        <button mat-icon-button (click)="selectMap()"><span class="material-symbols-rounded">map</span>
        </button>
        to create a new smap or open an existing one.</h4>
    </div>
    <div *ngIf="hasNodesNoNodes && !hasNoMap"
         class="no-map-container pt-3 d-flex justify-content-center align-items-center"
         [class.sidebar-open]="isSideBarOpen">
      <h4 class="text-center d-flex justify-content-center align-items-center">
        Please use
        <button mat-icon-button (click)="createNodeFromNoNodeMessage()">
          <span class="material-symbols-rounded">add</span>
        </button>
        to create a new node or right click in the canvas.</h4>
    </div>

    <mat-drawer-container  class="example-container" autosize [hasBackdrop]="false">
      <mat-drawer #drawer class="drawer"
                  mode="over"
                  mwlResizable
                  [enableGhostResize]="true"
                  [validateResize]="validateResize()"
                  [position]="'end'"
                  [style.width.px]="drawerWidth"
                  (resizeEnd)="onResizeEnd($event)"
                  autoFocus="false">
        <div mwlResizeHandle *ngIf="sidebarResizable" [resizeEdges]="{left: true}" class="resize-handle-left"></div>
        <div class="drawer-container">
          @if (focusedLink || focusedNode) {
            <maporium-sidebar-form [focusedNode]="focusedNode!"
                                   [focusedLink]="focusedLink!"
                                   [readonly]="currentMap?.isReadonly || this.isReadOnly || readModeAppState"
                                   (updateNode)="callNodeUpdate($event)"
                                   (updateMultiple)="callUpdateMultiple($event)"
                                   (resetMultiple)="multiReset($event)"
                                   (stateReset)="resetState($event)"
                                   (updateLink)="callLinkUpdate($event)"></maporium-sidebar-form>
          }

          @if (!focusedLink && !focusedNode) {
            <maporium-map-info-section (mapMetaChanged)="updateSelectedMapMetadata($event)"
                                       [hasNoMap]="hasNoMap"
                                       [readonly]="currentMap?.isReadonly || this.isReadOnly">
            </maporium-map-info-section>
          }
        </div>
      </mat-drawer>
      <mat-drawer-content>
        <block-ui name="UI">
          <div #cytoscapeContainer id="canvas" class="map-container"></div>
        </block-ui>

<!--        <maporium-zoom-level-indicator></maporium-zoom-level-indicator>-->

      </mat-drawer-content>

    </mat-drawer-container>

  </div>
  <app-tours-panel></app-tours-panel>
