import { Component } from '@angular/core';
import { MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatToolbar } from '@angular/material/toolbar';
import { MatList, MatListItem } from '@angular/material/list';
import { MatCard, MatCardTitle } from '@angular/material/card';
import { MatFormField } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { OpenaiService } from '../../../api/services/openai.service';
import { MatProgressBar } from '@angular/material/progress-bar';
import { finalize } from 'rxjs';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { generatePreviewCanvasData } from '../../helpers/generate-preview-canvas';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CytoscapeService } from '../../../services/cy-services/cytoscape.service';
import { MapService } from '../../../api/services/map.service';
import { SerializedMap } from '../../../api/interfaces/graph-node.interface';

interface Message {
  text: string;
  isSent: boolean;
  isCanvas: boolean;
  isUsers: boolean;
}

@Component({
  selector: 'app-chat-dialog',
  standalone: true,
  styleUrls: ['./ai-chat-dialog.component.scss'],
  template: `
    <mat-toolbar>
      <span>Maporium Assistant</span>
    </mat-toolbar>
    <mat-dialog-content>
      <mat-list style="min-height: 100px; height: 100%">
        <mat-list-item *ngFor="let message of messages; let i = index">
          @if(message.isCanvas) {
            <mat-card>
              <div [id]="'cy-preview_' + i" class="cy-preview"></div>
            </mat-card>
          } @else {
            <mat-card [ngClass]="{'users': message.isUsers}">
              <p>{{ message.text }}</p>
            </mat-card>
          }
        </mat-list-item>
      </mat-list>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="d-flex justify-content-center align-items-center w-100">
        <mat-form-field class="d-flex w-100" style="margin-right: 10px;" appearance="outline">
          <textarea matInput [(ngModel)]="newMessage" (keyup.enter)="sendMessage()" cdkTextareaAutosize></textarea>
        </mat-form-field>

        <button mat-icon-button (click)="sendMessage()" [disabled]="isLoading" class="d-flex">
          @if (!isLoading) {
            <mat-icon>send</mat-icon>
          } @else {
            <mat-progress-spinner diameter="24" mode="indeterminate"></mat-progress-spinner>
          }
        </button>

        @if (hasPreview) {
          <button mat-button (click)="add()">add</button>
        }
      </div>
    </mat-dialog-actions>
  `,
  imports: [
    MatDialogContent,
    MatToolbar,
    MatListItem,
    MatList,
    MatCard,
    MatFormField,
    CommonModule,
    FormsModule,
    MatInput,
    MatButton,
    MatIconButton,
    MatIcon,
    MatProgressBar,
    MatProgressSpinner,
    MatDialogActions,
    CdkTextareaAutosize,
    MatCardTitle
  ],
  styles: [`
    .sent-message {
      justify-content: flex-end;
    }

    .received-message {
      justify-content: flex-start;
    }

    mat-card {
      margin: 5px;
      padding: 10px;
    }
  `]
})
export class ChatDialogComponent {
  messages: Message[] = [];
  newMessage = '';
  isLoading = false;
  hasPreview = false;
  lastPreviewData: any;

  constructor(private dialogRef: MatDialogRef<ChatDialogComponent>,
              private cyService: CytoscapeService,
              private mapService: MapService,
              private generativeService: OpenaiService) {}

  sendMessage(): void {
    if (this.newMessage.trim()) {
      this.isLoading = true;
      const cyJson = this.cyService.cy?.json() as any;
      if (!cyJson) {
        this.isLoading = false;
        return;
      }

      console.log(cyJson.elements);
      const serialized: SerializedMap = {
        map: this.mapService.getCurrentSelectedMapFromStore(),
        nodes: cyJson.elements.nodes.map((n: { data: any; }) => n.data),
        links: cyJson.elements.edges.map((l: { data: any; }) => l.data)
      };
      console.log(serialized);
      this.generativeService.generateAnswer(this.newMessage, serialized)
        .pipe(
          finalize(() => this.isLoading = false)
        )
        .subscribe((response) => {
          const currentIndex = () => this.messages.length === 0 ? 0 : this.messages.length - 1;
          this.messages.push({ text: this.newMessage, isSent: true, isUsers: true, isCanvas: false});
          this.newMessage = '';
          if(response.responseType === 'data') {
            this.messages.push({ text: 'Preview', isSent: false, isCanvas: true, isUsers: false});
            this.hasPreview = true;
            this.lastPreviewData = response.data;
          } else {
            this.messages.push({ text: response.data as any, isSent: false, isCanvas: false, isUsers: false});
          }

          setTimeout(() => {
            generatePreviewCanvasData(response.data as { nodes: any[], links: any[] }, 'cy-preview_' + currentIndex());
          }, 300);
        });
    }
  }

  add() {
    this.dialogRef.close(this.lastPreviewData);
  }
}
